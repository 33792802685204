import React from "react"
import styled, { createGlobalStyle } from "styled-components"

import PageTemplate from '../components/PageTemplate'
import ContactForm from '../components/ContactForm'


const GlobalStyles = createGlobalStyle`
  body {
    background-repeat: no-repeat;
     
    /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#ffffff+0,ffffff+58,d6ebf8+90,d6ebf8+90,d6ebf8+100 */
    background: #ffffff; /* Old browsers */
    background: -moz-linear-gradient(left,  #ffffff 0%, #ffffff 58%, #d6ebf8 90%, #d6ebf8 90%, #d6ebf8 100%) fixed !important; /* FF3.6-15 */
    background: -webkit-linear-gradient(left,  #ffffff 0%,#ffffff 58%,#d6ebf8 90%,#d6ebf8 90%,#d6ebf8 100%) fixed !important; /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to right,  #ffffff 0%,#ffffff 58%,#d6ebf8 90%,#d6ebf8 90%,#d6ebf8 100%) fixed !important; /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#d6ebf8',GradientType=1 ) !important; /* IE6-9 */
  }
`

const PageContent = styled.div`
  position: relative;
  
  @media (min-width: ${props => props.theme.breakpointTablet}) {
    padding-top: 135px;
  }
`
const PageContentInner = styled.div`
  padding-left: ${props => props.theme.paddingSidesMobile};
  padding-right: ${props => props.theme.paddingSidesMobile};
  padding-top: 100px;
  padding-bottom: 40px;
  
  @media (min-width: ${props => props.theme.breakpointTablet}) {
    padding-left: ${props => props.theme.paddingSidesDesktop};
    padding-right: ${props => props.theme.paddingSidesDesktop};
    padding-top: 0;
    padding-bottom: 0;
  }
`
const CopyContainer = styled.div`
  width: 100%;
  margin-bottom: 20px;
  
  h1 {
    left: -${props => props.theme.paddingSidesMobile};
  }
  
  p {
    color: ${props => props.theme.blueDark};
    font-size: .7rem;
    line-height: 1.75;
    
    span {
        font-weight: 900;
    }
  }
  
  @media (min-width: ${props => props.theme.breakpointTablet}) {
  
      margin-bottom: 45px;
      
      h1 {
        left: -${props => props.theme.paddingSidesDesktop};
      }
      
      p {
        font-size: 1.1rem;
        margin-bottom: 0;
        width: 60%;
      }
  }
`
const ContactContent = styled.div`
    
    @media (min-width: ${props => props.theme.breakpointTablet}) {
        display: flex;
    }
`
const Address = styled.div`

    margin-bottom: 25px;

    h6 {
        font-weight: 900;
        font-size: 1rem;
        margin: 0;
        margin-bottom: .1rem;
    }
    
    p {
        line-height: 1.6;
    }
    
    @media (min-width: ${props => props.theme.breakpointTablet}) {
        width: 35%;
        margin-bottom: 0;
    }
`
const ContactFormContainer = styled.div`
    
    @media (min-width: ${props => props.theme.breakpointTablet}) {
        width: 65%;
        margin-bottom: 40px;
    }
`


class IndexPage extends React.Component {

    render() {

        return (
            <PageTemplate metaDataIndex={5} selectedNavIndex={5}>
                <GlobalStyles />
                <PageContent>
                    <PageContentInner role="main" id="main">
                        <CopyContainer>
                            <h1 className='bannerHeader'>Contact us to experience the difference</h1>
                        </CopyContainer>
                        <ContactContent>
                            <Address>
                                <h6>Headquarters</h6>
                                <p>
                                    909 Third Avenue
                                    <br/>
                                    New York, NY 10022
                                    <br/>
                                    United States
                                    <br/>
                                    Phone (212) 704-1200
                                </p>
                            </Address>
                            <ContactFormContainer>
                                <ContactForm formType='contact' />
                            </ContactFormContainer>
                        </ContactContent>

                    </PageContentInner>
                </PageContent>
            </PageTemplate>
        )
    }
}

export default IndexPage
